$lowWidth: 480px;
$midWidth: 717px;
$spacing: 30px;
$messageFormItemWidth: 230px;

* {
	box-sizing: border-box;
}

body {
    margin: 0;
}

:root {
    --sg-purple: #B92C91;
    --sg-yellow: #F8CE2A;
    --sg-blue: #16A3CC;
    --sg-camel-red: #E46F3C;
    --sg-coral: #E95556;
    --sg-orange: #D93B00;
    --sg-grey: #777474;
    --max-content-width: 700px;
    --modal-tint-color: #B92C91; // active color in mobile no prompt modal
}

header {
    position: fixed;
    z-index: 1;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    transition: background-color 800ms cubic-bezier(0.4, 0, 0.2, 1);
    
    .header-container {
        max-width:  var(--max-content-width);
        margin-top: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        // padding-left: $spacing;
        // padding-right: $spacing;
        padding: 0 $spacing 10px $spacing;
        align-items: center;
        .logo {
            display: flex;
            align-items: center;

            text-decoration: none;
            color: unset;
            // .logo-svg {
            //     fill: #E95556;
            //     filter: invert(0.5) sepia(1) saturate(3) hue-rotate(100deg);
            // }

            #logo-svg {
                fill: white;
            }

            .logo-title {
                font-size: 34px;
                margin-left: 10px;
                font-weight: 500;
            }
        }

        #header-btn {
            background-color: transparent;
            // filter: brightness(0.75);
            // filter: hue-rotate(343deg) saturate(2);

            // backdrop-filter: brightness(0.75); // without js style

            // .btn-text {
            //     filter: brightness(1);
                
            // }
        }

        #ham-menu {
            fill: white
        }

        @media (max-width: $lowWidth) {
            padding-bottom: 10px;
        }

        @media (max-width: $midWidth) {
            #header-btn {
                display: none;
            }

            #ham-menu {
                display: block;
            }
        }

        @media (min-width: $midWidth) {
            #header-btn {
                display: block;
            }
            #ham-menu {
                display: none;
            }
        }
    }

    &.yellow-bg {
        .header-container {
            .logo {
                #logo-svg {
                    fill: var(--sg-orange);
                }
            }
        }

        .logo-title {
            color: var(--sg-orange);
        }

        #ham-menu {
            fill: var(--sg-orange);
        }
    }

}

button, .button {
    border: none;
    height: fit-content;
    border-radius: 38px;
    // box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
    color: white;
    font-weight: normal;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 20px;
    line-height: 1.5;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    background-color: transparent;
    cursor: pointer;

    display: inline-block; //required for .button anchors to apply line height
}


sg-footer {
    width: 100%;
    footer {
        height: 130px;
        background-color: white;
        // position: sticky;
        width: 100%;
        // bottom: 0;
        // width: 100%;
    
        display: flex;
        justify-content: center;

        .close-btn-container {
            display: none;
        }
    
        @media (max-width: $midWidth) {
            display: none;
        }
    
        .footer-content {
            max-width: var(--max-content-width);
            padding: $spacing;
            width: 100%;
            .top-row {
                a {
                    text-decoration: none;
                    margin-right: 20px;
                    color: var(--sg-grey);
                    font-size: 14px;
                }
            }
    
            .bottom-row {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                margin-top: $spacing;
                align-items: flex-end;
    
                .store-icons {
                    display: flex;
                    img {
                        width: 100px;
                        height: 30px;
                        margin-right: $spacing;
                    }
                }
            }
        }
    }
}

sg-modal-menu {
    footer {
        display: none;
        @media (max-width: $midWidth) {
        &.show-as-menu {
            transition: transform 0.5s ease;
            padding: $spacing;
            display: block;
            position: fixed;
            transform: translateY(110%);
            z-index: 4;
            background-color: white;
            // height: 100vh;
            // width: 100vw;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            .footer-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .top-row {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .close-btn-container {
                        display: block;
                        text-align: right;
                        margin-top: 10px;
                        
                        .close-btn {
                            img {
                                cursor: pointer;
                            }
                        }
                    }

                    a {
                        text-decoration: none;
                        &:not(.close-btn) {
                            text-align: left;
                            font-size: 22px;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 500;
                            color: var(--sg-purple);
                            margin-top: $spacing;
                        }
                    }
                }

                .bottom-row {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    font-size: 18px;

                    .store-icons{
                        display: flex;
                        flex-direction: column;
                    img {
                            width: 200px;
                            height: 60px;
                            margin-bottom: $spacing;
                    }
                    }

                }
            }

            &.shown {
                //display: block;
                transform: translateY(0%);
            }
        }
        }
    }
}

input, textarea, select {
    border: none;
    padding: 17px;
    border-radius: 25px;
    margin: 20px 0px;

    outline: none;
    font-size: 14px;

    width: $messageFormItemWidth;
    font-family: 'Montserrat', sans-serif;

    resize: none; //textarea
}

.mobileno-prompt {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
    z-index: 4;

    .modal-container {
        background-color: white;
        padding: $spacing;
        box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
        border-radius: 20px;


        .title-container {
            margin-bottom: 20px;
            color: var(--modal-tint-color, --sg-purple);
            font-size: 20px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            font-weight: 500;
            // .left-filler, .title, .close-btn {
            //     flex: 1;
            // }
            .close-btn {
                cursor: pointer;
            }
        }


        .desc {
            text-align: center;
            margin-bottom: 20px;
        }
        
        .select-container {
            position: relative;
            display: inline-flex;
            &::after {
                display: inline-flex;
                content: "\f107";
                color: var(--modal-tint-color, --sg-purple);
                // transform: rotate(90deg);
                // -webkit-transform: rotate(90deg);
                font-size: 22px;
                font: var(--fa-font-solid);
                // margin-top: 5px;
                position: absolute;
                // right: 5px;
                // top: -1px;
                justify-content: end;
                height: 100%;
                right: 5px;
                align-items: center;

            }
            select {
                width: 100px;
                background-color: white;
                -webkit-appearance:none; //sarafi fix
            }
        }

        .phoneno-combo {
            border: 2px solid var(--modal-tint-color, --sg-purple);;
            border-radius: 25px;
            input, select {
                margin: 0px;
            }
        }

        .btn_container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            button {
               background-color: var(--modal-tint-color, --sg-purple);;
            }
        }
        
    }

    &.shown {
        display: flex;
        
    }
}

.close-btn-container {
    display: block;
    width: 100%;
    text-align: right;
    img {
        width: 18px;
        height: 18px;
    }
    a {
        cursor: pointer;
    }
}

/**** Toaster styles ******/
/* Animations to fade the snackbar in and out */
// @-webkit-keyframes fadein {
//     from {
//         top: 0;
//         opacity: 0;
//     }
//     to {
//         top: 30px;
//         opacity: 1;
//     }
// }
// @keyframes fadein {
//     from {
//         top: 0;
//         opacity: 0;
//     }
//     to {
//         top: 30px;
//         opacity: 1;
//     }
// }
// @-webkit-keyframes fadeout {
//     from {
//         top: 30px;
//         opacity: 1;
//     }
//     to {
//         top: 0;
//         opacity: 0;
//     }
// }
// @keyframes fadeout {
//     from {
//         top: 30px;
//         opacity: 1;
//     }
//     to {
//         top: 0;
//         opacity: 0;
//     }
// }

@keyframes slidein {
    from {
        top: -30px;
        // opacity: 0;
    }
    to {
        top: 30px;
        // opacity: 1;
    }
}

@keyframes slideout {
    from {
        top: 30px;
        // opacity: 0;
    }
    to {
        top: -30px;
        // opacity: 1;
    }
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar-container {
    // visibility: hidden; /* Hidden by default. Visible on click */
    display: flex;
    justify-content: center;

    &.show {
        visibility: visible;
        // -webkit-animation: fadein 0.5s;
        // animation: fadein 0.5s;
        // transition: visibility 0.5s;
        

        #snackbar {
            // animation: slidein 0.5s;
            top: 30px
        }
    }

    #snackbar {
        min-width: 250px;
        color: #5a5a5a;
        text-align: center;
        padding-left: 10px;
        position: fixed;
        z-index: 5;
        top: -100px;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        // animation: slideout 0.5s;
        transition-property: top;
        transition-duration: 0.5s;

        &.success {
            background-color: #14928c;
            #front-icon {
                // background-image: url(images/tick.svg);
                color: #14928c;
                .fa-solid {
                    &::before {
                        content: "\f058";
                    }
                }
            }
        }

        &.error {
            background-color:  red;
            #front-icon {
                color: red;
                .fa-solid {
                    &::before {
                        content: "\f057";
                    }
                }
            }
        }


        #msg-header {
            display: flex;
            font-size: 20px;
            justify-content: space-between;
            align-items: center;
        }
        .icon {
            display: inline-block;
            height: 20px;
            width: 20px;
            margin-right: 10px;
            background-size: contain;
        }
        #close {
            // background-image: url(images/close.svg);
            img {
                height: 15px;
                // fill: #fff;
                margin-top: 8px;
                cursor: pointer;
                margin-left: 10px;
            }

        }
        #msg-container {
            background-color: #fff;
            border-radius: 0px 10px 10px 0px;
            padding: 10px;
            text-align: left;
        }
        #msg {
            margin-left: 30px;
            margin-right: 35px;
            font-size: 16px;
        }
    }
    
}
