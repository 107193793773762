


// @font-face {
//     font-family: "Quicksand";
//     src: url("/src/fonts/Quicksand-Regular.ttf") format('truetype');
//     // font-weight: normal;
// }

@import "./common.scss";
$minMediaHeight: 500px;

body {
    margin: 0;
    height: 100vh;
    // width: 100vw;
    font-family: 'Quicksand', sans-serif;
    overscroll-behavior: none;
}

.container {
    width: 100%;
    height: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    // scroll-behavior: smooth;

    // @media (max-width: $midWidth) {
    //     scroll-snap-type: y proximity;
    // }

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    section {
        min-height: 100%;
        // width: 100vw;
        scroll-snap-align: start;
        

        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        
        @media (max-width: $midWidth) {
            height: auto;
        }

        .header-bg {
            position: sticky;
            top: 0;
            height: 75px;
            width: 100%;
            background-color: inherit;
        }
    
        &#sec-1 {
            background-color: var(--sg-purple);
            --action-btn-color: var(--sg-purple);
            // .header-bg {
            //     background-color: var(--sg-purple);
            // }
        }
    
        &#sec-2 {
            background-color: var(--sg-yellow);
            --action-btn-color: var(--sg-orange);
           div {
            color: var(--sg-orange);
           }
        //    .header-bg {
        //         background-color: var(--sg-yellow);
        //     }
        }
    
        &#sec-3 {
            background-color: var(--sg-blue);
            --action-btn-color: var(--sg-blue);

            .line2 {
                margin-left: $spacing;
                display: flex;
                align-items: flex-start;
                .title-large{
                    margin-left: 10px;
                }
            }
        }
    
        &#sec-4 {
            background-color: var(--sg-camel-red);
            --action-btn-color: var(--sg-camel-red);

            .right {
                img {
                    border-radius: 30px;
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    
        &#sec-5 {
            background-color: var(--sg-coral);
            --action-btn-color: var(--sg-coral);

            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            .action-btn-container {
                width: $messageFormItemWidth;
            }

            .row {
                flex: 1;
            }

            // @media (max-width: $midWidth) {
            //     min-height: 100vh;
            //     // padding-top: 80px;
            //     .right {
            //         padding-top: 0px;
            //         scroll-snap-align: center;
            //     }

            //     .col {
            //         &:nth-child(1) {
            //             padding-top: 100px;
            //             padding-bottom: 50px;
            //             scroll-snap-align: start;
            //         }
            //     }
            // }

            @media (min-width: $midWidth) {
                // allow scroll to view footer 717 up
                // smaller heigh screens snaps back causing footer not completely visible
                // height: 100vh;
                // overflow-y: scroll;

                &::-webkit-scrollbar { // hide scrollbar
                    display: none;
                }
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
            }
        }


        .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            @media (min-width: $lowWidth) { 
                // padding-top: 80px;
            }

            max-width: var(--max-content-width);
            // justify-content: center;

            .col {
                flex: 1;
                color: white;
                // height: 100%;
                padding: $spacing;
                overflow: hidden;

                // overflow-y: scroll;

                
                &.right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    @media (max-width: $midWidth) {
                        justify-content: center;
                    }
                }


                .title-small {
                    font-size: 29px;
                    font-weight: 500;
                }

                .title-large {
                    font-size: 57px;
                    font-weight: 500;
                }

                .description {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 18px;
                    margin-top: 20px;
                    line-height: 30px;
                }

                video {
                    max-height: 80%;
                    border-radius: 25px;
                    max-width: 100%;
                    min-height: $minMediaHeight;
                    background: transparent; // force render to prevent flicker
                }
                
                img {
                    max-width: 100%;
                    max-height: 90%;
                    object-fit: contain;
                    min-height: $minMediaHeight;
                }
            } 
        }
    
    
        @media (max-width: $midWidth) {

            scroll-snap-align: unset; // need to disable section snap to get col snap working in ios15
            // &:not(#sec-5) { // we want sec-5 cols in same snap
                .row {
                    display: block;
                    width: 100%; //fixes ios15 getting a horizontal bounce scroll and on relaod content cut issue

                    -webkit-overflow-scrolling: touch;
                    /** ios 15 individula column snap **/
                    // scroll-snap-type: y mandatory;
                    // overflow-y: scroll;
                    // height: 100vh;
                     /** ios 15 individula column snap  end - causing issue in android**/
                    .col {
                        height: 100vh;
                        padding-top: 120px;
                        scroll-snap-align: start;
                    }
                }
            // }
        }
    }
}



.sgiggles-text {
    font-size: 22px;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;

}

.action-btn-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.action-btn {
    border: none;
    border-radius: 38px;
    box-shadow: 3px 3px 3px 0 rgb(0 0 0 / 16%);
    color: var(--action-btn-color);
    background-color: white;
    // text-transform: uppercase;
    font-weight: bold;
    // font-family: 'Poppins';
    text-decoration: none;
    line-height: 1.5;

    padding: 5px 5px 5px 20px;

    align-items: center;
    display: flex;
    max-width: max-content;
    flex-wrap: nowrap;
    word-break: unset;

    &::after {
        content: "\f105";
        // content: "\f138";
        background-color: var(--action-btn-color);
        color: white;
        font-size: 22px;
        font-weight: bold;
        // padding: 0px 10px;
        border-radius: 50%;
        // line-height: 1;
        height: 35px;
        width: 35px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font: var(--fa-font-solid);
        // font-family: 'Varela Round', sans-serif;
    }
}
