* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

:root {
  --sg-purple: #B92C91;
  --sg-yellow: #F8CE2A;
  --sg-blue: #16A3CC;
  --sg-camel-red: #E46F3C;
  --sg-coral: #E95556;
  --sg-orange: #D93B00;
  --sg-grey: #777474;
  --max-content-width: 700px;
  --modal-tint-color: #B92C91;
}

header {
  position: fixed;
  z-index: 1;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  transition: background-color 800ms cubic-bezier(0.4, 0, 0.2, 1);
}
header .header-container {
  max-width: var(--max-content-width);
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 30px 10px 30px;
  align-items: center;
}
header .header-container .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: unset;
}
header .header-container .logo #logo-svg {
  fill: white;
}
header .header-container .logo .logo-title {
  font-size: 34px;
  margin-left: 10px;
  font-weight: 500;
}
header .header-container #header-btn {
  background-color: transparent;
}
header .header-container #ham-menu {
  fill: white;
}
@media (max-width: 480px) {
  header .header-container {
    padding-bottom: 10px;
  }
}
@media (max-width: 717px) {
  header .header-container #header-btn {
    display: none;
  }
  header .header-container #ham-menu {
    display: block;
  }
}
@media (min-width: 717px) {
  header .header-container #header-btn {
    display: block;
  }
  header .header-container #ham-menu {
    display: none;
  }
}
header.yellow-bg .header-container .logo #logo-svg {
  fill: var(--sg-orange);
}
header.yellow-bg .logo-title {
  color: var(--sg-orange);
}
header.yellow-bg #ham-menu {
  fill: var(--sg-orange);
}

button, .button {
  border: none;
  height: fit-content;
  border-radius: 38px;
  color: white;
  font-weight: normal;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 20px;
  line-height: 1.5;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
}

sg-footer {
  width: 100%;
}
sg-footer footer {
  height: 130px;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}
sg-footer footer .close-btn-container {
  display: none;
}
@media (max-width: 717px) {
  sg-footer footer {
    display: none;
  }
}
sg-footer footer .footer-content {
  max-width: var(--max-content-width);
  padding: 30px;
  width: 100%;
}
sg-footer footer .footer-content .top-row a {
  text-decoration: none;
  margin-right: 20px;
  color: var(--sg-grey);
  font-size: 14px;
}
sg-footer footer .footer-content .bottom-row {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 30px;
  align-items: flex-end;
}
sg-footer footer .footer-content .bottom-row .store-icons {
  display: flex;
}
sg-footer footer .footer-content .bottom-row .store-icons img {
  width: 100px;
  height: 30px;
  margin-right: 30px;
}

sg-modal-menu footer {
  display: none;
}
@media (max-width: 717px) {
  sg-modal-menu footer.show-as-menu {
    transition: transform 0.5s ease;
    padding: 30px;
    display: block;
    position: fixed;
    transform: translateY(110%);
    z-index: 4;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  sg-modal-menu footer.show-as-menu .footer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  sg-modal-menu footer.show-as-menu .footer-content .top-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  sg-modal-menu footer.show-as-menu .footer-content .top-row .close-btn-container {
    display: block;
    text-align: right;
    margin-top: 10px;
  }
  sg-modal-menu footer.show-as-menu .footer-content .top-row .close-btn-container .close-btn img {
    cursor: pointer;
  }
  sg-modal-menu footer.show-as-menu .footer-content .top-row a {
    text-decoration: none;
  }
  sg-modal-menu footer.show-as-menu .footer-content .top-row a:not(.close-btn) {
    text-align: left;
    font-size: 22px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: var(--sg-purple);
    margin-top: 30px;
  }
  sg-modal-menu footer.show-as-menu .footer-content .bottom-row {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    font-size: 18px;
  }
  sg-modal-menu footer.show-as-menu .footer-content .bottom-row .store-icons {
    display: flex;
    flex-direction: column;
  }
  sg-modal-menu footer.show-as-menu .footer-content .bottom-row .store-icons img {
    width: 200px;
    height: 60px;
    margin-bottom: 30px;
  }
  sg-modal-menu footer.show-as-menu.shown {
    transform: translateY(0%);
  }
}

input, textarea, select {
  border: none;
  padding: 17px;
  border-radius: 25px;
  margin: 20px 0px;
  outline: none;
  font-size: 14px;
  width: 230px;
  font-family: "Montserrat", sans-serif;
  resize: none;
}

.mobileno-prompt {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
}
.mobileno-prompt .modal-container {
  background-color: white;
  padding: 30px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 20px;
}
.mobileno-prompt .modal-container .title-container {
  margin-bottom: 20px;
  color: var(--modal-tint-color, --sg-purple);
  font-size: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 500;
}
.mobileno-prompt .modal-container .title-container .close-btn {
  cursor: pointer;
}
.mobileno-prompt .modal-container .desc {
  text-align: center;
  margin-bottom: 20px;
}
.mobileno-prompt .modal-container .select-container {
  position: relative;
  display: inline-flex;
}
.mobileno-prompt .modal-container .select-container::after {
  display: inline-flex;
  content: "\f107";
  color: var(--modal-tint-color, --sg-purple);
  font-size: 22px;
  font: var(--fa-font-solid);
  position: absolute;
  justify-content: end;
  height: 100%;
  right: 5px;
  align-items: center;
}
.mobileno-prompt .modal-container .select-container select {
  width: 100px;
  background-color: white;
  -webkit-appearance: none;
}
.mobileno-prompt .modal-container .phoneno-combo {
  border: 2px solid var(--modal-tint-color, --sg-purple);
  border-radius: 25px;
}
.mobileno-prompt .modal-container .phoneno-combo input, .mobileno-prompt .modal-container .phoneno-combo select {
  margin: 0px;
}
.mobileno-prompt .modal-container .btn_container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.mobileno-prompt .modal-container .btn_container button {
  background-color: var(--modal-tint-color, --sg-purple);
}
.mobileno-prompt.shown {
  display: flex;
}

.close-btn-container {
  display: block;
  width: 100%;
  text-align: right;
}
.close-btn-container img {
  width: 18px;
  height: 18px;
}
.close-btn-container a {
  cursor: pointer;
}

/**** Toaster styles ******/
/* Animations to fade the snackbar in and out */
@keyframes slidein {
  from {
    top: -30px;
  }
  to {
    top: 30px;
  }
}
@keyframes slideout {
  from {
    top: 30px;
  }
  to {
    top: -30px;
  }
}
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar-container {
  display: flex;
  justify-content: center;
}
#snackbar-container.show {
  visibility: visible;
}
#snackbar-container.show #snackbar {
  top: 30px;
}
#snackbar-container #snackbar {
  min-width: 250px;
  color: #5a5a5a;
  text-align: center;
  padding-left: 10px;
  position: fixed;
  z-index: 5;
  top: -100px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition-property: top;
  transition-duration: 0.5s;
}
#snackbar-container #snackbar.success {
  background-color: #14928c;
}
#snackbar-container #snackbar.success #front-icon {
  color: #14928c;
}
#snackbar-container #snackbar.success #front-icon .fa-solid::before {
  content: "\f058";
}
#snackbar-container #snackbar.error {
  background-color: red;
}
#snackbar-container #snackbar.error #front-icon {
  color: red;
}
#snackbar-container #snackbar.error #front-icon .fa-solid::before {
  content: "\f057";
}
#snackbar-container #snackbar #msg-header {
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
}
#snackbar-container #snackbar .icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  background-size: contain;
}
#snackbar-container #snackbar #close img {
  height: 15px;
  margin-top: 8px;
  cursor: pointer;
  margin-left: 10px;
}
#snackbar-container #snackbar #msg-container {
  background-color: #fff;
  border-radius: 0px 10px 10px 0px;
  padding: 10px;
  text-align: left;
}
#snackbar-container #snackbar #msg {
  margin-left: 30px;
  margin-right: 35px;
  font-size: 16px;
}

body {
  margin: 0;
  height: 100vh;
  font-family: "Quicksand", sans-serif;
  overscroll-behavior: none;
}

.container {
  width: 100%;
  height: 100%;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.container::-webkit-scrollbar {
  display: none;
}
.container section {
  min-height: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 717px) {
  .container section {
    height: auto;
  }
}
.container section .header-bg {
  position: sticky;
  top: 0;
  height: 75px;
  width: 100%;
  background-color: inherit;
}
.container section#sec-1 {
  background-color: var(--sg-purple);
  --action-btn-color: var(--sg-purple);
}
.container section#sec-2 {
  background-color: var(--sg-yellow);
  --action-btn-color: var(--sg-orange);
}
.container section#sec-2 div {
  color: var(--sg-orange);
}
.container section#sec-3 {
  background-color: var(--sg-blue);
  --action-btn-color: var(--sg-blue);
}
.container section#sec-3 .line2 {
  margin-left: 30px;
  display: flex;
  align-items: flex-start;
}
.container section#sec-3 .line2 .title-large {
  margin-left: 10px;
}
.container section#sec-4 {
  background-color: var(--sg-camel-red);
  --action-btn-color: var(--sg-camel-red);
}
.container section#sec-4 .right img {
  border-radius: 30px;
  max-width: 100%;
  height: auto;
}
.container section#sec-5 {
  background-color: var(--sg-coral);
  --action-btn-color: var(--sg-coral);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.container section#sec-5 .action-btn-container {
  width: 230px;
}
.container section#sec-5 .row {
  flex: 1;
}
@media (min-width: 717px) {
  .container section#sec-5 {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .container section#sec-5::-webkit-scrollbar {
    display: none;
  }
}
.container section .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: var(--max-content-width);
}
.container section .row .col {
  flex: 1;
  color: white;
  padding: 30px;
  overflow: hidden;
}
.container section .row .col.right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
@media (max-width: 717px) {
  .container section .row .col.right {
    justify-content: center;
  }
}
.container section .row .col .title-small {
  font-size: 29px;
  font-weight: 500;
}
.container section .row .col .title-large {
  font-size: 57px;
  font-weight: 500;
}
.container section .row .col .description {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  margin-top: 20px;
  line-height: 30px;
}
.container section .row .col video {
  max-height: 80%;
  border-radius: 25px;
  max-width: 100%;
  min-height: 500px;
  background: transparent;
}
.container section .row .col img {
  max-width: 100%;
  max-height: 90%;
  object-fit: contain;
  min-height: 500px;
}
@media (max-width: 717px) {
  .container section {
    scroll-snap-align: unset;
  }
  .container section .row {
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    /** ios 15 individula column snap **/
    /** ios 15 individula column snap  end - causing issue in android**/
  }
  .container section .row .col {
    height: 100vh;
    padding-top: 120px;
    scroll-snap-align: start;
  }
}

.sgiggles-text {
  font-size: 22px;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
}

.action-btn-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.action-btn {
  border: none;
  border-radius: 38px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
  color: var(--action-btn-color);
  background-color: white;
  font-weight: bold;
  text-decoration: none;
  line-height: 1.5;
  padding: 5px 5px 5px 20px;
  align-items: center;
  display: flex;
  max-width: max-content;
  flex-wrap: nowrap;
  word-break: unset;
}
.action-btn::after {
  content: "\f105";
  background-color: var(--action-btn-color);
  color: white;
  font-size: 22px;
  font-weight: bold;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: var(--fa-font-solid);
}
/*# sourceMappingURL=index.3889492f.css.map */
